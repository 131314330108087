<template>
    <div class="container mx-auto">
        <h1>Welcome My Portfolio</h1>
    </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  }
}
</script>
