<template>
    <div>
        <!-- Nav Bar -->
        <nav class="relative container mx-auto p-6">
            <!-- Flex Container -->
            <div class="flex items-center justify-between">
                <!-- Logo -->
                <div class="pt-2">
                    <div class="bg-zinc-400 rounded">
                      <h1 class="p-3">DREAMDEV</h1>
                    </div>
                </div>
                <!-- Menu Items -->
                <div class="hidden space-x-6 md:flex items-center">
                    <a href="" class="hover:text-darkGrayIshBlue">Home</a>
                    <a href="" class="hover:text-darkGrayIshBlue">Project</a>
                    <a href="" class="hover:text-darkGrayIshBlue">About</a>
                    <!-- Buttons -->
                  <a href="" class="md:block p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight">Contact Now</a>
                </div>
            </div>
        </nav>
        <!-- Hero Section -->
        <section id="hero">
            <div class="container flex flex-col-reverse  items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
                <!-- Left Items -->
                <div class="flex flex-col mb-32 space-y-12 md:w-1/2">
                    <h1 class="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
                        Learning is brighting your life.
                    </h1>
                    <p class="max-w-sm text-center text-darkGreyIsBlue md:text-left">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Modi voluptatem nulla nobis doloremque quidem eius, blanditiis illo harum facere perferendis.
                    </p>
                    <div class="flex justify-center md:justify-start">
                      <a href="" class="md:block p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight">Contact Me</a>
                    </div>
                </div>
                <!-- Image -->
                <div class="md:w-1/2">
                    <img src="./assets/hero.jpg" alt="">
                </div>
            </div>
        </section>
        <!-- Features Section -->
        <section id="features">
            <div class="container flex justify-center flex-col px-4 mx-auto mt-10 space-y-12 md:space-y-0 md:flex-row">
                <!-- What Differences -->
                <div class="flex flex-col space-y-6 md:w-1/2">
                    <h2 class="max-w-md text-4xl font-bold text-center md:text-left">What is difference?</h2>
                    <p class="max-w-sm text-center text-darkGreyIsBlue md:text-left">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa tenetur animi pariatur ea, eius omnis veniam, sapiente impedit fugiat mollitia nam aut nemo molestias dicta? Error perferendis natus porro incidunt quo. Suscipit impedit facere veritatis tempora natus assumenda, architecto officiis.</p>
                </div>
                <!-- Number List -->
                <div class="flex flex-col space-y-6 md:w-1/2">
                    <!-- List item 1 -->
                    <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
                        <!-- Heading 1 -->
                        <div class="rounded-l-full rounded bg-brightRedSupLight md:bg-transparent">
                            <div class="flex items-center space-x-2">
                                <div class="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                                    01
                                </div>
                                <h3 class="text-base font-bold md-mb-4 md:hidden">
                                    Track Learning Process
                                </h3>
                            </div>
                        </div>
                        <div>
                          <h3 class="hidden mb-4 text-lg font-bold md:block">
                            Track Learning Process
                          </h3>
                          <p class="text-darkGrayIsBlue">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia quas ea debitis eius ducimus distinctio neque quasi cumque officia inventore earum aut, amet velit adipisci possimus esse dolor ut! Maxime possimus autem modi voluptatem earum eum voluptatibus nulla debitis deserunt!
                          </p>
                        </div>
                    </div>
                    <!-- List item 2 -->
                    <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
                        <!-- Heading 1 -->
                        <div class="rounded-l-full rounded bg-brightRedSupLight md:bg-transparent">
                            <div class="flex items-center space-x-2">
                                <div class="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                                    02
                                </div>
                                <h3 class="text-base font-bold md-mb-4 md:hidden">
                                    Track Learning Process
                                </h3>
                            </div>
                        </div>
                        <div>
                          <h3 class="hidden mb-4 text-lg font-bold md:block">
                            Track Learning Process
                          </h3>
                          <p class="text-darkGrayIsBlue">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia quas ea debitis eius ducimus distinctio neque quasi cumque officia inventore earum aut, amet velit adipisci possimus esse dolor ut! Maxime possimus autem modi voluptatem earum eum voluptatibus nulla debitis deserunt!
                          </p>
                        </div>
                    </div>
                    <!-- List item 3 -->
                    <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
                        <!-- Heading 1 -->
                        <div class="rounded-l-full rounded bg-brightRedSupLight md:bg-transparent">
                            <div class="flex items-center space-x-2">
                                <div class="px-4 py-2 text-white rounded-full md:py-1 bg-brightRed">
                                    03
                                </div>
                                <h3 class="text-base font-bold md-mb-4 md:hidden">
                                    Track Learning Process
                                </h3>
                            </div>
                        </div>
                        <div>
                          <h3 class="hidden mb-4 text-lg font-bold md:block">
                            Track Learning Process
                          </h3>
                          <p class="text-darkGrayIsBlue">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia quas ea debitis eius ducimus distinctio neque quasi cumque officia inventore earum aut, amet velit adipisci possimus esse dolor ut! Maxime possimus autem modi voluptatem earum eum voluptatibus nulla debitis deserunt!
                          </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Testimonial Section -->
        <section id="testimonial">
            <div class="max-w-6xl px-5 mx-auto mt-32 text-center">
                <h2 class="text-4xl font-bold text-center">
                    What's is difference about management.
                </h2>
            </div>
            <div class="flex flex-col mt-24 md:flex-row md:space-x-6">
                <div class="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:1/3">
                    <img src="./assets/person.jpg" class="w-20 -mt-14 rounded-full" alt="Testtt">
                    <h5 class="text-lg font-bold">MR.John Smith</h5>
                    <p class="text-sm text-darkGrayIsBlue">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis asperiores adipisci molestias corporis. Ipsam sequi cum, voluptates ex laboriosam soluta.
                    </p>
                </div>
                <div class="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:1/3">
                  <img src="./assets/person.jpg" class="w-20 -mt-14 rounded-full" alt="Testtt">
                    <h5 class="text-lg font-bold">MISS.Selena</h5>
                    <p class="text-sm text-darkGrayIsBlue">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis asperiores adipisci molestias corporis. Ipsam sequi cum, voluptates ex laboriosam soluta.
                    </p>
                </div>
                <div class="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:1/3">
                  <img src="./assets/person.jpg" class="w-20 -mt-14 rounded-full" alt="Testtt">
                    <h5 class="text-lg font-bold">MR.John Terry</h5>
                    <p class="text-sm text-darkGrayIsBlue">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis asperiores adipisci molestias corporis. Ipsam sequi cum, voluptates ex laboriosam soluta.
                    </p>
                </div>
            </div>
            <!-- Button -->
            <div class="my-16">
                <div class="flex justify-center">
                    <a href="" class="md:block p-3 px-6 pt-2 text-white bg-brightRed rounded-full baseline hover:bg-brightRedLight">Get Started</a>
                </div>
            </div>
        </section>
        <!-- CTA Section -->
        <section id="cta" class="bg-brightRed">
            <!-- Flex Container -->
            <div class="container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
                <!--Heading-->
                <h2 class="text-5xl font-bold lead  ing-tight text-center text-white md:text-4xl md:max-w-xl md:text-left">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem, doloremque?
                </h2>
                <!--Button-->
                <div class="flex justify-center md:justify-start">
                    <a href="" class="md:block p-3 px-6 pt-2 text-brightRed bg-white rounded-full shadow-2xl baseline hover:bg-gray-900">Get Started</a>
                </div>
            </div>
        </section>
        <section class="bg-veryDarkBlue" id="footer">
            <div class="container flex flex-col-reverse items-center justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
                <div class="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0:md:items-start">
                    <!--Logo-->
                    <div class="pt-2">
                        <div class="bg-zinc-400 rounded">
                            <h1 class="p-3">DREAMDEV</h1>
                        </div>
                    </div>
                    <!--Social Link Container -->
                    <div class="flex justify-center space-x-4 text-white">
                        <!-- Link 1 -->
                        <a href="#">
                            Facebook
                        </a>
                        <!-- Link 1 -->
                        <a href="#">
                            Whatapp
                        </a>
                        <!-- Link 1 -->
                        <a href="#">
                            Twitter
                        </a>
                        <!-- Link 1 -->
                        <a href="#">
                            Instagram
                        </a>
                    </div>
                </div>
                <!-- List Container -->
                <div class="flex justify-around space-x-32">
                    <div class="flex flex-col space-y-3 text-white">
                        <a href="" class="hover:text-brightRed">Home</a>
                        <a href="" class="hover:text-brightRed">Pricing</a>
                        <a href="" class="hover:text-brightRed">Product</a>
                        <a href="" class="hover:text-brightRed">About</a>
                    </div>
                    <div class="flex flex-col space-y-3 text-white">
                        <a href="" class="hover:text-brightRed">Careers</a>
                        <a href="" class="hover:text-brightRed">Community</a>
                        <a href="" class="hover:text-brightRed">Privacy Policy</a>
                    </div>
                </div>
                <!-- Input Container -->
                <div class="flex flex-col justify-between">
                    <form>
                        <div class="flex space-x-3">
                        <input
                            type="text"
                            class="flex-1 px-4 rounded-full focus:outline-none"
                            placeholder="Updated in your inbox"
                        />
                        <button
                            class="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-brightRedLight focus:outline-none"
                        >
                            Go
                        </button>
                        </div>
                    </form>
                    <div class="hidden text-white md:block">
                        Copyright &copy; 2022, All Rights Reserved
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
